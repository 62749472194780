@import url("https://fonts.googleapis.com/css?family=Fira+Sans+Extra+Condensed:300,400,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Courgette&display=swap");
@import '~vuetify/dist/vuetify.min.css';
@import '~@mdi/font/css/materialdesignicons.css';

.v-data-table__wrapper {
  &::-webkit-scrollbar{
    margin-top: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(126, 124, 124, 0.76);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(243, 239, 239, 0.726);
  }

  table tr td {
    padding: 10px 5px;
  }
}

.better-scrollbar, .v-autocomplete__content {
  //Chrome and updated browsers Only
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(126, 124, 124, 0.76);
    // border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(243, 239, 239, 0.726);
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
th,
strong {
  font-family: "Fira Sans Extra Condensed", helvetica, sans-serif;
  font-weight: 600 !important;
}

p,
a,
b,
td,
span,
li,
ol,
label,
.v-list-item,
.v-toolbar__title,
.v-card__title,
.v-text-field,
.v-footer {
  font-family: "Fira Sans Extra Condensed", helvetica, sans-serif;
  font-weight: 400 !important;
}

small,
blockquote,
.v-tab {
  font-family: "Fira Sans Extra Condensed", helvetica, sans-serif;
  font-weight: 300 !important;
}

.monitor-header {
  font-size: 1em !important;
  text-align: center !important;
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter, .component-fade-leave-to /* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.selected-sidebar {
  background-color: #fff !important;
  .v-list-item__avatar .v-icon,
  .v-list-item__title {
    color: var(--v-primary-base) !important;
  }
}

.selected-sidebar-dark,
.selected-sidebar-dark:before {
  background-color: var(--v-secondary-base) !important;

  .v-list-item__avatar .v-icon,
  .v-list-item__title {
    color: #fff !important;
  }
}

.font-family__ {
  &fira_sans {
    font-family: "Fira Sans Extra Condensed", helvetica, sans-serif !important;
    i,
    span {
      font-family: "Fira Sans Extra Condensed", helvetica, sans-serif !important;
    }
  }

  &courgette {
    font-family: "Courgette", cursive !important;
    i,
    span {
      font-family: "Courgette", cursive !important;
    }
  }
}

.v-timeline-item__dot {
  background: rgba(255, 255, 255, 0.8) !important;
}

.snackbar-transition {
  transition: all 500ms ease-in-out;
}

.selected span table tr td {
  color: #fff;
}

.unit-info-table {
  font-weight: 200 !important;
  font-size: 12px;
  text-align: center;
  width: 33%;
}